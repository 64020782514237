<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Gestión de estudiantes
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar, eliminar y cambiar el estado de los
              estudiantes.</span
            >
          </h3>
        </div>
        <div class="card-toolbar"></div>
      </div>
      <div class="card-body">
        <!-- begin::Search Form -->
        <div class="mb-5">
          <div class="row align-items-center">
            <div class="col-lg-12 col-xl-9">
              <div class="row align-items-center">
                <div class="col-md-4 my-2 my-md-0">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    prepend-icon=""
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Buscar..."
                    v-model="students.search"
                  ></v-text-field>
                </div>
                <div class="col-md-4 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      :label="grades.isLoading ? 'Cargando grados' : 'Grado'"
                      :loading="grades.isLoading"
                      :disabled="!grades.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="grades.data"
                      item-text="name"
                      item-value="id"
                      v-model="students.filters.grade_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ students.filters.grade_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-4 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      :label="
                        specialities.isLoading
                          ? 'Cargando especialidades'
                          : 'Especialidad'
                      "
                      :loading="specialities.isLoading"
                      :disabled="!specialities.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="specialities.data"
                      item-text="name"
                      item-value="id"
                      v-model="students.filters.speciality_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ students.filters.speciality_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Search Form -->
        <!--begin: Datatable-->
        <v-card>
          <v-data-table
            :headers="students.headers"
            :items="filteredData"
            :search="students.search"
            :items-per-page="10"
            :loading="students.isLoading"
          >
            <template v-slot:[`item.code`]="{ item }">
              <template>
                <div>
                  {{ item.code }}
                  <v-icon
                    color="grey"
                    small
                    class="ml-1"
                    @click="copyToClipboard(item.code)"
                    >mdi-content-copy</v-icon
                  >
                </div>
              </template>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <template>
                <v-chip
                  class="ma-2"
                  :color="
                    `${item.status == 'Activo' ? 'success' : 'red darken-1'}`
                  "
                  outlined
                >
                  <v-icon left>
                    mdi-{{
                      item.status == "Activo"
                        ? "account-outline"
                        : "account-off-outline"
                    }}
                  </v-icon>
                  <span class="font-weight-bold">{{ item.status }}</span>
                </v-chip>
              </template>
            </template>
            <!-- begin: Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="getStudentInformationUpdated(item.uuid)"
                      color="blue"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>Ver más información</span>
                </v-tooltip>
              </template>
              <!-- Begin:Open modal to fill the economial responsable person-->
              <template
                v-if="
                  existInArray(
                    'Gestionar responsable economico',
                    currentPageActions
                  )
                "
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="showEconomicManagerModal(item.uuid)"
                      class="ml-3"
                      color="orange"
                      v-bind="attrs"
                      v-on="on"
                      medium
                    >
                      mdi-account-cash
                    </v-icon>
                  </template>
                  <span>Sostenedor económico</span>
                </v-tooltip>
              </template>
              <template
                v-if="
                  existInArray('no access created yet!', currentPageActions)
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :disabled="isActiveStudent(item)"
                      class="ml-5"
                      @click="redirectToStudentForm(item.uuid)"
                      color="amber darken-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-account-reactivate
                    </v-icon>
                  </template>
                  <span>Actualizar datos</span>
                </v-tooltip>
              </template>
              <template v-if="existInArray('Eliminar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectStudent(item);
                        showMdlDelete();
                      "
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
            </template>
            <!-- end: Acions -->
          </v-data-table>
        </v-card>
        <!-- end: Datatable-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <StudentInformationModal
      ref="mdlStudentInformation"
      :student="selectedStudent"
      :canSeeStudentCredentials="canSeeStudentCredentials"
    ></StudentInformationModal>

    <RegisterStudentEconomicManagerModal
      ref="RegisterStudentEconomicManagerModal"
      :economicManagerInfo="economicManager"
    >
    </RegisterStudentEconomicManagerModal>

    <DeleteBaseModal ref="mdlDelete" @delete="deleteStudent">
      <template v-slot:title>¿Desea eliminar al estudiante?</template>
      <template v-slot:subject>
        el estudiante
        <span class="font-weight-bolder">{{ selectedStudent.firstName }}</span>
      </template>
    </DeleteBaseModal>

    <ChangeStatusBaseModal
      ref="mdlChangeStatus"
      :selected="selectedStudent"
      @change-status="changeStatusStudent"
    >
      <template v-slot:title>
        ¿Deseas cambiar el estado de al estudiante?
      </template>
      <template v-slot:subject>
        el estudiante
        <span class="font-weight-bolder">{{ selectedStudent.name }}</span>
      </template>
    </ChangeStatusBaseModal>

    <v-dialog v-model="loadingDialog" hide-overlay width="300">
      <v-card color="primary" class="pa-5">
        <div class="d-flex flex-column">
          <p class="text-h6 font-weight-bold text-center white--text">
            Obteniendo datos de estudiante
          </p>
          <p class="text-h6 mb-0 text-center white--text">
            {{ selectedStudent.code }}
          </p>
          <p class="text-h6 mb-0 text-center mb-2 white--text">
            {{ selectedStudent.firstName }} <br />
            {{ selectedStudent.lastName }}
          </p>
        </div>
        <v-card-text>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end::Modals -->
  </div>
</template>

<script>
import studentRepository from "@/repositories/studentRepository";
import StudentInformationModal from "@/components/modals/students/StudentInformationModal.vue";
import RegisterStudentEconomicManagerModal from "@/components/modals/students/RegisterStudentEconomicManagerModal";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";
import ChangeStatusBaseModal from "@/components/modals/ChangeStatusBaseModal.vue";
import academicGroupRepository from "@/repositories/academicGroupRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";
import gradeRepository from "@/repositories/gradeRepository";
import specialityRepository from "@/repositories/specialityRepository";
import { getStudentEconomicManager } from "@/repositories/economicManagerRepository";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "Students",
  title: "Estudiantes | GE ITR",
  components: {
    StudentInformationModal,
    DeleteBaseModal,
    ChangeStatusBaseModal,
    RegisterStudentEconomicManagerModal,
  },
  data() {
    return {
      students: {
        headers: [
          { text: "Nombres", value: "first_name" },
          { text: "Apellidos", value: "last_name" },
          { text: "Código", value: "code" },
          { text: "Correo institucional", value: "email" },
          { text: "Grado", value: "grade" },
          { text: "Especialidad", value: "speciality" },
          { text: "Estado", value: "status" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
      },
      selectedStudent: {},
      academicGroups: [],
      technicalGroups: [],
      grades: { data: [], isLoading: false },
      specialities: { data: [], isLoading: false },
      economicManager: {},
      isLoadingIndividualStudentInformation: false,
      loadingDialog: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Estudiantes", route: "students" },
    ]);
    // Check if the URL parameter 'uuid' exists
    if (this.$route.query.uuid) {
      this.getStudentInformationUpdated(this.$route.query.uuid);
    }
    this.loadStudents();
    this.getGrades();
    this.getSpecialities();
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Estudiantes");
  },

  methods: {
    //--------------Functions triggered directly by user actions------------
    async fetchData(endpoint, headers) {
      let baseURL;

      if (process.env.ADMISION_APP_ENV === "production") {
        baseURL = process.env.ADMISION_API_URL;
      } else if (process.env.ADMISION_APP_ENV === "development") {
        baseURL = process.env.ADMISION_DEV_API_URL;
      } else {
        baseURL = process.env.ADMISION_LOCAL_API_URL;
      }

      let url = `${baseURL}/${endpoint}`;

      const response = await fetch(url, { headers });
      return response.json();
    },

    async fetchDataInStudentsApi(endpoint, headers) {
      let baseURL;

      if (process.env.APP_ENV === "production") {
        baseURL = process.env.GE_STUDENTS_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.GE_STUDENTS_DEV_API_URL;
      } else {
        baseURL = process.env.GE_STUDENTS_LOCAL_API_URL;
      }

      let url = `${baseURL}/${endpoint}`;
      const response = await fetch(url, { headers });
      return response.json();
    },

    async selectStudent(obj, academicLevelDetails) {
      let uuid = obj.uuid;
      //later on we'll need to add some type of auth
      let headers = {
        Authorization: `${uuid}`,
      };

      // invoking complementing functions
      let studentDiseases = this.fetchData(
        "student-diseases/diseases",
        headers
      );
      let studentGraduatesRelative = this.fetchData(
        "student-relatives/relative/graduated",
        headers
      );
      let studentActiveRelative = this.fetchData(
        "student-relatives/relative/active",
        headers
      );
      let studentPrescribedMedicines = this.fetchData(
        "student-medicines/medicine/prescribed",
        headers
      );
      let studentAllergicMedicines = this.fetchData(
        "student-medicines/medicine/allergic",
        headers
      );
      let studentManagers = this.fetchData("student-managers", headers);
      let studentSacraments = this.fetchData("student-sacraments", headers);
      let studentFamilyDetails = this.fetchData(
        "student-familiar-informations",
        headers
      );
      let economicManager = this.fetchDataInStudentsApi(
        `economic-managers/${uuid}`,
        headers
      );

      Promise.all([
        studentDiseases,
        studentGraduatesRelative,
        studentActiveRelative,
        studentPrescribedMedicines,
        studentAllergicMedicines,
        studentManagers,
        studentSacraments,
        studentFamilyDetails,
        economicManager,
      ])
        .then((data) => {
          this.selectedStudent = {
            diseases: data[0],
            relativesGraduated: data[1],
            relativesActive: data[2],
            prescribedMedicines: data[3],
            allergicMedicines: data[4],
            managers: data[5],
            sacraments: data[6],
            familyDetails: data[7],
            economicManager: data[8],
            details: { ...obj },
            academicLevelDetails: { ...academicLevelDetails },
          };
        })
        .catch((error) => {
          console.warn(
            "Hubo un error al obtener los datos del estudiante: > ",
            error
          );
        });

      this.showMdlStudentInformation();
    },

    unselectStudent() {
      this.selectedStudent = {};
    },

    showMdlStudentInformation() {
      this.loadingDialog = false;
      this.$refs.mdlStudentInformation.toggleModal();
    },

    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },

    showMdlChangeStatus() {
      this.$refs.mdlChangeStatus.toggleModal();
    },

    //---------------------------------------------------------------------

    //----------------------Fetching all student Data----------------------

    // 1. First, fetching all students
    loadStudents() {
      this.students.isLoading = true;
      studentRepository
        .getAllWithAcademicInformationIncludingDisabled()
        .then(({ data }) => {
          this.students.data = data;
        })
        .catch((err) => {
          console.warn("Error al obtener información de estudiante..", err);
        })
        .finally(() => {
          this.students.isLoading = false;
        });
    },

    //2. Second, Fetching the all the individual information from the selected student
    getStudentInformationUpdated(studentUUID) {
      this.loadingDialog = true;
      this.isLoadingIndividualStudentInformation = true;

      let fetchStudentInfo = studentRepository.getStudentInformationByUUID(
        studentUUID
      );
      let fetchStudentDetails = studentRepository.getStudentInformation({
        headers: {
          Authorization: `${studentUUID}`,
        },
      });

      Promise.all([fetchStudentInfo, fetchStudentDetails])
        .then((responses) => {
          let academicLevelDetails = responses[0].data;
          let fullStudentDetails = responses[1].data;

          //"fullStudentDetails" contanins detailed student information
          //"academicLevelDetails" contains general academic details
          //Both pieces of information come from diferent API's
          this.selectStudent(fullStudentDetails, academicLevelDetails);
        })
        .catch((err) => {
          console.warn(
            "Error al obtener información general de estudiante",
            err
          );
        })
        .finally(() => {
          this.isLoadingIndividualStudentInformation = false;
        });
    },

    //----------------------Altering student information-------------------
    deleteStudent() {
      studentRepository
        .deleteStudent(this.selectedStudent.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            this.getStudents();
          }
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: `No es posible eliminar el registro ${this.selectedStudent.name} en estos momentos 🙁`,
          });
        });
    },

    redirectToStudentForm(studentUUID) {
      let baseURL, fullURL;

      if (process.env.FORM_ENV === "production") {
        baseURL = process.env.FORM_PRODUCTION_DOMAIN;
      } else if (process.env.FORM_ENV === "development") {
        baseURL = process.env.FORM_DEVELOPMENT_URL;
      } else {
        baseURL = process.env.FORM_DEVELOPMENT_URL;
      }

      fullURL = `${baseURL}/#/student/${studentUUID}`;
      window.open(fullURL, "_blank");
    },

    changeStatusStudent() {
      studentRepository
        .changeStatusStudent(this.selectedStudent.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            this.getStudents();
          }
        })
        .catch((error) => {
          console.log(error);
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: `No es posible cambiar el estado del registro seleccionado en estos momentos 🙁`,
          });
        });
    },
    //---------------------------------------------------------------------

    getAcademicGroups() {
      academicGroupRepository
        .getAllAcademicGroups()
        .then(({ data }) => {
          this.academicGroups = data;
        })
        .catch((err) => {
          console.warn("Error al obtener grupos académicos", err);
          // Sweetalert that indicates the operation cannot be completed
        });
    },
    getTechnicalGroups() {
      technicalGroupRepository
        .getAllTechnicalGroups()
        .then(({ data }) => {
          this.technicalGroups = data;
        })
        .catch((err) => {
          console.warn("Error al obtener grupos técnicos", err);
          // Sweetalert that indicates the operation cannot be completed
        });
    },
    getGrades() {
      this.grades.isLoading = true;
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.grades.data = data;
        })
        .catch((err) => {
          console.warn("Error al obtener los grados", err);
          // Sweetalert that indicates the operation cannot be completed
        })
        .finally(() => {
          this.grades.isLoading = false;
        });
    },
    getSpecialities() {
      this.specialities.isLoading = true;
      specialityRepository
        .getAllSpecialities()
        .then(({ data }) => {
          this.specialities.data = data;
        })
        .catch((err) => {
          console.warn("Error al obtener las especialidades", err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.specialities.isLoading = false;
        });
    },

    isActiveStudent(item) {
      return item.status == "Activo" ? false : true;
    },
    // Actions for register economic manager
    showEconomicManagerModal(studentUuid) {
      this.loadingDialog = true;
      // Check if student has already economic manager
      getStudentEconomicManager(studentUuid)
        .then((data) => {
          this.economicManager = data.data;
          this.economicManager.uuid = studentUuid;
        })
        .catch((err) => {
          console.warn("Error al obtener los datos del sostenedor economico", err);
  
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.loadingDialog = false;
          this.$refs.RegisterStudentEconomicManagerModal.toggleModal();
        });
    },
  },

  computed: {
    ...mapGetters(["currentPageActions"]),
    filteredData() {
      return this.filterData(this.students.filters, this.students.data);
    },

    geApiUrl() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DEV_API_URL;
      } else {
        baseURL = process.env.LOCAL_API_URL;
      }
      return baseURL;
    },
    canSeeStudentCredentials() {
      return this.existInArray("Ver credenciales", this.currentPageActions);
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
